import Lottie from 'lottie-web/build/player/lottie_light';

const Class = {
    animations: [],
    max_tries: 5,
    tries: 0,

    init() {
        // Initialize dynamic block preview (editor).
        if (window.acf) {
            Class.initializeBlock();
            window.acf.addAction('render_block_preview/type=lottie', Class.initializeBlock);
        } else {
            Class.initializeBlock();
        }
    },

    initializeBlock() {
        var animation_blocks = document.querySelectorAll('.wp-block-lottie, .wp-block-ono-blocks-hero');

        Class.destroyAll();

        animation_blocks.forEach((element) => {
            var lottie_animation = element.querySelector('.lottie-animation');

            if (lottie_animation === null || lottie_animation.dataset.path === '') {
                if (Class.tries < Class.max_tries) {
                    Class.tries++;
                    setTimeout(Class.initializeBlock, 1000);
                }
                return;
            }

            var animation = Lottie.loadAnimation({
                container: lottie_animation, // the dom element that will contain the animation
                renderer: 'svg',
                loop: JSON.parse(lottie_animation.dataset.loop),
                autoplay: false,
                path: lottie_animation.dataset.path, // the path to the animation json
            });

            animation.addEventListener('DOMLoaded', () => {
                lottie_animation.setAttribute('style', 'opacity:1 !important');
            });

            Class.animations.push(animation);
        });

        Class.playAll();
    },

    destroyAll() {
        Class.animations.forEach((animation) => {
            animation.destroy();
        });
        Class.animations = []; // Clear animations array
    },

    playAll() {
        Class.animations.forEach((animation) => {
            animation.play();
        });
    },

    old_blocks: null,
    monitorBlockUpdates() {
        const { select, subscribe } = wp.data;

        const unsubscribe = subscribe(() => {
            const { getBlocks } = select('core/block-editor');
            const blocks = getBlocks();

            let shouldReinitialize = false;

            blocks.forEach((block, index) => {
                if (block.name === 'ono-blocks/hero' && Class.old_blocks && block !== Class.old_blocks[index]) {
                    shouldReinitialize = true;
                }
            });

            if (shouldReinitialize) {
                Class.tries = 0; // Reset tries counter
                setTimeout(Class.initializeBlock, 1000);
            }
            Class.old_blocks = blocks;
        });
    },
};

if (!window.acf) {
    Class.init();
}

export default Class;
